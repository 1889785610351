var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"art-card",class:{ 'art-card--new': _vm.card.new, 'art-card--tiny': _vm.tiny },style:(_vm.pathLink === 'none' ? 'pointer-events: none': '')},[_c('router-link',{attrs:{"to":{
      name: 'emerging.workdetails',
      params: {
        pArtist: _vm.card.artist.replace(/ /g, '-'),
        pTitle: _vm.card.title.replace(/ /g, '-'),
        id: _vm.id,
      },
    }}},[_c('figure',{staticClass:"art-card__figure"},[_c('img',{attrs:{"loading":"lazy","src":_vm.card.url,"alt":_vm.card.title}})])]),_c('div',{staticClass:"art-card__header"},[_c('div',{staticClass:"art-card__artist"},[_vm._v(" "+_vm._s(_vm.card.artist)+" ")]),_c('h2',{staticClass:"art-card__title font-weight-medium"},[_c('router-link',{attrs:{"to":{
          name: 'emerging.workdetails',
          params: {
            pArtist: _vm.card.artist.replace(/ /g, '-'),
            pTitle: _vm.card.title.replace(/ /g, '-'),
            id: _vm.id,
          },
        }}},[_c('div',[_vm._v(_vm._s(_vm.card.title))])])],1)]),_c('div',{staticClass:"art-card__prices"},[_c('div',[_vm._v(" "+_vm._s(((_vm.$t("total")) + ":"))+" "),_c('strong',[_vm._v(_vm._s(_vm.card.currentValue > 0 ? _vm.formatAmount(_vm.card.currency, _vm.card.currentValue) : '-'))])]),(_vm.card.currentFrameValue)?_c('div',[_vm._v(" "+_vm._s(((_vm.$t("frameValue")) + ":"))+" "),_c('strong',[_vm._v(_vm._s(_vm.formatAmount(_vm.card.currency, _vm.card.currentFrameValue)))]),_c('a',{staticClass:"tax-text"},[_vm._v(" +"+_vm._s(_vm.$t("taxTiny"))+" ")])]):_vm._e()]),(_vm.card.new)?_c('div',{staticClass:"art-card__new"},[_vm._v("New")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }