<template>
  <div>

    <Collection
      :cards="collection"
      :title="decodeURIComponent(pArtist)"
      :cardsPerRow="4"
    />
    
  </div>
</template>

<script>
import Collection from "@/components/Templates/EmergingArtistCollection";

import EmergingArtworkDS from "@/services/EmergingArtworkDS";

export default {
  name: "vEmergingArtistCollection",
  props: {
    pArtist: String,
    id: String,
  },
  components: {
    Collection,
  },
  data: () => ({
    collection: [],
    featured: [],
  }),
  beforeMount() {
    EmergingArtworkDS.getPublishedArtistWorkFS(this.id).then((results) => {
      this.featured = results.featured;
      this.collection = results.firstRound;
    });

    // this.collection = [{
    //   id: '123456',
    //   title: 'Untitled 1',
    //   artist: 'Sisti Franz',
    //   src: 'https://images.neoone.art/emerging/emerging_test.jpg'
    // },
    // {
    //   id: '1234561',
    //   title: 'Untitled 2',
    //   artist: 'Sisti Franz',
    //   src: 'https://images.neoone.art/emerging/emerging_test.jpg'
    // },
    // {
    //   id: '1234562',
    //   title: 'Untitled 3',
    //   artist: 'Sisti Franz',
    //   src: 'https://images.neoone.art/emerging/emerging_test.jpg'
    // },
    // {
    //   id: '1234563',
    //   title: 'Untitled 4',
    //   artist: 'Sisti Franz',
    //   src: 'https://images.neoone.art/emerging/emerging_test.jpg'
    // },
    // {
    //   id: '1234564',
    //   title: 'Untitled 5',
    //   artist: 'Sisti Franz',
    //   src: 'https://images.neoone.art/emerging/emerging_test.jpg'
    // }]
    // EmergingArtworkDS.getPublishedArtworkRT().then((results) => {
    //   this.featured = results.featured;
    //   this.firstRound = results.firstRound;
    // });
    // EmergingArtworkDS.getPreprodArtworkRT().then((preprod) => {
    //   preprod.forEach((e) => {
    //     this.preprod.push(e)
    //   })
    // });
    
    
    
  },
};
</script>
