<template>
  <article
    :style="pathLink === 'none' ? 'pointer-events: none': ''"
    class="art-card"
    :class="{ 'art-card--new': card.new, 'art-card--tiny': tiny }"
  >
    <router-link
      :to="{
        name: 'emerging.workdetails',
        params: {
          pArtist: card.artist.replace(/ /g, '-'),
          pTitle: card.title.replace(/ /g, '-'),
          id: id,
        },
      }"
    >
      <figure class="art-card__figure">
        <img loading="lazy" :src="card.url" :alt="card.title" />
      </figure>
    </router-link>

    <div class="art-card__header">
      <div class="art-card__artist">
        {{ card.artist }}
      </div>
      <h2 class="art-card__title font-weight-medium">
        <router-link
          :to="{
            name: 'emerging.workdetails',
            params: {
              pArtist: card.artist.replace(/ /g, '-'),
              pTitle: card.title.replace(/ /g, '-'),
              id: id,
            },
          }"
        >
          <div>{{ card.title }}</div>
        </router-link>
      </h2>
    </div>

    <div class="art-card__prices">
      <div>
        {{ `${$t("total")}:` }}
        <strong>{{ card.currentValue > 0 ? formatAmount(card.currency, card.currentValue) : '-' }}</strong>
      </div>
      <div v-if="card.currentFrameValue">
        {{ `${$t("frameValue")}:` }}
        <strong>{{
          formatAmount(card.currency, card.currentFrameValue)
        }}</strong>
        <a class="tax-text"> +{{ $t("taxTiny") }} </a>
      </div>
    </div>

    <div v-if="card.new" class="art-card__new">New</div>
  </article>
</template>

<script>
import helpers from "@/mixins/helpers.js";

export default {
  name: "ArtCard",
  mixins: [helpers],
  components: {},
  props: {
    id: String,
    card: Object,
    title: String,
    tiny: Boolean,
    pathLink: String,
  },
  data: () => ({
    hover: false,
  }),
};
</script>

<style lang="scss" scoped>
.tax-text {
  font-size: 10px;
}
.art-card {
  $this: &;
  background: white;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 6px;
  box-shadow: var(--shadow1);
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) 1ms;
  &:hover {
    box-shadow: var(--shadow1-over);
  }

  &--new .art-card__new {
    position: absolute;
    top: 0px;
    right: 0;
    background-color: #ffe038;
    padding: 4px 8px 10px;
    border-radius: 0 4px 0 4px;
    height: 24px;
    line-height: 16px;
    font-size: 12px;
    font-weight: 600;
  }

  &__figure {
    height: 300px;
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: center;
    padding: 16px;
    #{$this}--tiny & {
      max-height: 280px;
    }
    @media only screen and (min-width: 400px) {
      height: 500px;
    }
    img {
      max-width: 100%;
      object-fit: contain;
      max-height: 100%;
      margin: 0 auto;
      object-position: center;
    }
  }

  &__header {
    padding: 4px 16px 16px;
    @media only screen and (min-width: 1024px) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      min-height: 60px;
    }
  }

  &__title a {
    text-decoration: none;
    color: currentColor;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
  }

  &__artist {
    margin-bottom: auto;
    font-style: italic;
    @media only screen and (min-width: 1024px) {
      order: 1;
    }
  }

  &__prices {
    padding: 0 16px 16px;
    margin-top: auto;
    @media only screen and (min-width: 1600px) {
      padding: 0 16px 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      #{$this}--tiny & {
        display: block;
      }

      > :last-child {
        flex-grow: 1;
        margin-left: 8px;
        padding-left: 8px;
        height: 20px;
        border-left: 1px solid currentColor;
      }
      > :only-child {
        border-left: 0;
        margin-left: 0;
        padding-left: 0;
      }
    }
  }
}
</style>
